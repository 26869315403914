.root {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.toggler {
  position: relative;
  width: 64px;
  height: 36px;
  border: 1px solid var(--color-grey);
  border-radius: 18px;
}

.toggler:before {
  content: '';
  position: absolute;
  top: 4px;
  left: 4px;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background: var(--color-grey);
  transition: right .2s, left .2s, background-color .2s;
}

.root.checked .toggler:before {
  background: var(--color-main);
  left: calc(100% - 30px);
}

.label {
  margin-left: 16px;
  font-size: 16px;
}

.root input {
  display: none;
}
