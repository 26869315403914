.wrapper1 {
  overflow-y: visible;
}

.wrapper2 {
  margin: -12px;
}

.root {
  table-layout: fixed;
  width: 100%;
  border-spacing: 12px;
  border-collapse: separate;
  font-size: 16px;
}

.root th {
  background: var(--color-blue-extra-light);
  padding: 10px;
  text-align: center;
  font-size: 12px;
}

.root th:empty {
  background: none;
  padding: 0;
}

.root td {
  padding: 8px 10px 20px;
  border-bottom: 1px solid;
  border-image-source: linear-gradient(
      90deg,
      transparent 0,
      transparent 10px,
      var(--color-grey-dark) 10px,
      var(--color-grey-dark),
      var(--color-grey-dark) calc(100% - 10px),
      transparent calc(100% - 10px),
      transparent 100%
  );
  border-image-slice: 1;
}

.drag {
  width: 30px;
}

.dragDots {
  svg {
    max-width: 10px;
    height: auto;
  }
}
