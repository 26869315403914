.root {
  display: flex;
  flex-flow: row wrap;
  gap: 40px;
}

.link {
  font-size: 20px;
  color: var(--color-main-extra-dark);
  border-bottom: 2px solid transparent;

  &:hover {
    border-bottom-color: var(--color-blue);
  }
}

.active {
  color: var(--color-main);
  border-bottom-color: var(--color-blue);
}
