.root {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  place-content: stretch flex-start;
  align-items: stretch;
  height: 100vh;
}

.main {
  margin-top: 70px;
  height: calc(100vh - 70px);
}

.header {
  position: absolute;
  top: -70px;
  left: 0;
  height: 70px;
  width: 100%;
  padding: 20px 40px 0;
}

.auth {
  background-image: url("../../../public/img/login_bg.jpg");
  background-position: center bottom;
  background-size: cover;
  background-repeat: no-repeat;
}

.content {
  display: flex;
  flex-flow: column nowrap;
  order: 0;
  flex: 1 1 auto;
  align-self: auto;
  padding: 40px;
}
