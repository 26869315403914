.root {
    position: relative;
    display: flex;
    gap: 16px;
    padding: 24px;
    border-radius: 8px;
    background: white;
}

.root label {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}
