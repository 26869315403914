.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;

  .title {
    font-size: 26px;
    font-weight: 500;
    line-height: 1;
  }
}

#checkbox-tree {
  border-bottom: 1px solid var(--color-main);
}

#checkbox-tree button {
  background: transparent;
  border: none;
  padding: 0;
  margin: 0 0 0 10px;
  display: flex;
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
}

#checkbox-tree li > span {
  display: flex;
  flex-direction: row;
  border: none;
  border-top: 1px solid var(--color-main);
  padding: 15px 0;

  & > button {
    order: 1;
  }

  & > label {
    order: 0;
    display: flex;
  }

  input {
    width: 20px;
    height: 20px;
  }
}

#checkbox-tree > ol > li > span{
  & > button{
    display: none;
  }

  & > span {
    font-size: 20px;
  }
}

#checkbox-tree ol ol li > span{
  padding-left: 20px;
}

#checkbox-tree ol ol ol li > span{
  padding-left: 40px;
}

#checkbox-tree ol ol ol ol li > span{
  padding-left: 60px;
}

#checkbox-tree label > span:last-child {
  padding-left: 10px;
  font-size: 16px;
  color: var(--color-main);
  font-weight: bold;
}

#checkbox-tree .search {
  background-color: var(--color-blue-light);
}

.not-found {
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: bold;
  color: var(--color-red);
}
