*,
*:after,
*:before {
  box-sizing: border-box;
}

*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

*::-webkit-scrollbar-track {
  background: var(--color-grey-extra-light);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--color-main);
}

@supports (-moz-appearance: none) {
  * {
    scrollbar-width: thin;
    scrollbar-color: var(--color-main) var(--color-grey-extra-light);
  }
}

body {
  font-family: "Euclid Square", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
  text-align: left;
  color: var(--color-main);
  background-color: var(--color-grey-light);
}

body * {
  font-family: "Euclid Square", sans-serif;
}

#root {
  display: flex;
  flex-flow: column nowrap;
  min-height: 100vh;
}

a {
  text-decoration: none;
}

input {
  outline: none;
}
