.root {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 7px;
  font-size: 16px;
}

.select {
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
}

.value {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 48px;
  padding: 0 20px;
  gap: 20px;
  position: relative;
  z-index: 5;
  border: 1px solid var(--color-grey);
  border-radius: 8px;
  cursor: pointer;

  .textValue {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  input {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    background-color: transparent;
    color: var(--color-main);
    font-size: 16px;
  }
}

.errorContainer {
  position: absolute;
  max-width: 100%;
  top: 100%;
  left: 0;
  margin-top: 5px;
  color: var(--color-red);
  font-size: 14px;
  line-height: 17.75px;
  font-weight: 400;
}

.error .select,
.error .value{
  border-color: var(--color-red);
}

.optionsWrapper{
  overflow: hidden;
  position: absolute;
  left: 0;
  top: calc(100% - 10px);
  padding-top: 10px;
  width: 100%;
  background-color: var(--color-grey-light);
  border: 1px solid var(--color-grey);
  border-top: none;
  border-radius: 0 0 8px 8px;
  display: flex;
  z-index: 10;
}

.options {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  overflow-y: auto;
  max-height: 165px;
}

.option {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;

  .mark {
    border: 1px solid var(--color-main);
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
  }

  &.multi {
    .mark {
      border-radius: 4px;
    }
  }

  &.selected .mark{
    border: 1px solid var(--color-blue);
  }

  &.selected:not(.multi) {
    .mark {
      position: relative;

      &:before {
        content: '';
        position: absolute;
        display: block;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: var(--color-blue);
        top: calc(50% - 2px);
        left: calc(50% - 2px);
      }
    }
  }

  svg path {
    stroke: var(--color-blue);
  }
}

.placeholder {
  color: var(--color-main-light);
  cursor: pointer;
}

.open {
  .value {
    z-index: 15;
    border-color: var(--color-blue);
    color: var(--color-blue);
    background-color: var(--color-grey-light);
  }

  .selected,
  .placeholder {
    color: var(--color-blue);
  }

  .optionsWrapper {
    border-color: var(--color-blue);
  }

  .option:hover {
    color: var(--color-blue);
    text-decoration: underline;
  }
}

.disabled {
  .value {
    color: var(--color-main-dark);
    cursor: default;
  }

  .arrow path {
    stroke: var(--color-main-light) !important;
  }
}

.label-left .value {
  padding: 0;
}

.type-tiny {
  .value {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;
    gap: 5px;
    height: auto;

    input {
      color: var(--color-main);
    }
  }

  &.open .value {
    padding: 0 10px;
  }

  .optionsWrapper {
    border: none;
    min-width: 100%;
    width: auto;
    left: unset;
    right: 0;
    padding-top: 0;
    top: 100%;
  }

  &.hasValue .value {
    color: var(--color-blue);
    padding: 0 10px;
  }

  &.hasValue svg {
    stroke: var(--color-blue);
  }
}

.label-left {
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;

  .select {
    width: auto;
  }

  &.hasValue .value {
    padding: 0 10px;
  }
}
