.standardSelect {
  width: 480px;
}

.yearSelect {
  width: 160px;
}

.tableBlock {
  max-height: 575px;
  margin-bottom: 30px;
}

.charts {
  display: flex;
  flex-flow: row nowrap;
  gap: 30px;
}

.pieChart {
  flex: 0 0 380px;
}

.lineChart {
  flex: 1 1 100%;
}
