:root {
  --color-main: #314560;
  --color-main-extra-dark:  rgb(49 69 96 / 80%);
  --color-main-dark:  rgb(49 69 96 / 60%);
  --color-main-light: rgb(49 69 96 / 40%);
  --color-main-extra-light: rgb(49 69 96 / 10%);

  --color-white: #FFF;
  --color-white-light: rgb(255 255 255 / 40%);

  --color-orange: #FB9B2B;
  --color-orange-light: #FFE7CB;

  --color-red: #D12D38;
  --color-red-light: #FFDFE1;

  --color-pink: #E3439A;

  --color-blue: #1F6FE7;
  --color-blue-light: rgb(31 111 231 / 50%);
  --color-blue-extra-light: #E9F2FF;

  --color-green-dark: #3E9A13;
  --color-green: #87D010;
  --color-green-light: #E3F3DC;

  --color-grey: #DBDBDB;
  --color-grey-dark: #CED3D9;
  --color-grey-light: #F0F0F0;
  --color-grey-extra-light: rgb(166 166 166 / 40%);
}
