.root {
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  .nameColumn {
    width: 30%;
  }

  thead {
    position: sticky;
    top: 0;

    th {
      box-shadow: -6px 0 0 0 var(--color-white), 6px 0 0 0 var(--color-white);
    }
  }
}
