.root {
  display: flex;
  align-items: center;
  justify-content: center;

  max-width: 675px;
  width: 100%;
  height: 48px;
  overflow: hidden;

  background-color: var(--color-white);
  border: 1px solid var(--color-main);
  border-radius: 8px;
}

.root input {
  width: 100%;
  height: 100%;
  padding: 0 0 0 14px;
  border: none;
  outline: none;
  color: var(--color-main);
  font-size: 16px;
}

.root input::placeholder {
  color: var(--color-main-light);
}

.icon {
  padding: 8px;
  margin: 0 17px 0 10px;
  width: 30px;
  height: 30px;
  flex: 1 0 30px;
}

.icon-search {
  padding: 7px;
  margin: 0 18px;
}

.button-search {
  border-radius: 0 8px 8px 0;
}

.button-search:not(.disabled):hover {
  border-top: 0;
  border-bottom: 0;
  border-right: 0;
}

.root.type-mini {
  border: 1px solid transparent;

  &.focused {
    border: 1px solid var(--color-blue);
  }

  .icon,
  .icon-search {
    margin: 0 10px;
  }
}
