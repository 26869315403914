.root {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  gap: 7px;
}

.input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 48px;
  padding: 13px 20px;
  position: relative;
  z-index: 5;
  border: 1px solid var(--color-grey);
  border-radius: 8px;
  color: var(--color-main);
  font-size: 16px;
}

.input:disabled {
  color: var(--color-main-dark);
}

.input::placeholder {
  color: var(--color-main-light);
}

.input:focus {
  border: 1px solid var(--color-blue);
}

textarea.input {
  resize: none;
}

.root.error .input {
  border: 1px solid var(--color-red);
}

.errorMessage {
  position: absolute;
  max-width: 100%;
  top: 100%;
  left: 0;
  margin-top: 5px;
  font-size: 14px;
  color: var(--color-red);
}
