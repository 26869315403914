.page {
  display: flex;
  flex-flow: column nowrap;
  flex: 1;
}

.header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-bottom: 30px;
}

.title {
  font-size: 36px;
  font-weight: 500;
  line-height: 1;
}

.headerControls {
  display: flex;
  flex-flow: row nowrap;
  gap: 40px;
}

.filters {
  display: flex;
  flex-flow: row nowrap;
  gap: 40px;
  margin-bottom: 30px;
}

.block {
  flex: 1;
  border-radius: 10px;
  background: var(--color-white);
  padding: 20px;
}
