.root {
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  gap: 0 12px;

  max-height: 100%;
  max-width: 100%;
  min-height: 48px;
  padding: 0 25px;

  overflow: hidden;
  cursor: pointer;

  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 8px;

  color: var(--color-main);
  font-size: 16px;
  text-decoration: none;

  svg {
    width: auto;
    height: 24px;
  }
}

.fill {
  background-color: var(--color-main);
  color: var(--color-white);
}

.fill:not(.disabled):hover {
  background-color: transparent;
  color: var(--color-main);
  border: 1px solid var(--color-main);
}

.border {
  border: 1px solid var(--color-main);
  color: var(--color-main);
}

.border:not(.disabled):hover {
  background-color: var(--color-main);
  color: var(--color-white);
}

.dashed {
  border: 1px dashed var(--color-main);
  color: var(--color-main);
}

.dashed:not(.disabled):hover {
  background-color: var(--color-main);
  color: var(--color-white);
}

.text {
  text-decoration: none;
  min-height: 0;
  padding: 0;
}

.text:not(.disabled):hover {
  text-decoration: underline;
}

.link {
  border-radius: 0;
  min-height: auto;
  padding: 0;
}

.disabled {
  cursor: not-allowed;
  background-color: var(--color-grey);
  color: var(--color-main-light);
  border: 0;
  text-decoration: none;
}

.fill svg {
  fill: var(--color-white);
  stroke: var(--color-white);
}

.text svg,
.border svg {
  fill: var(--color-main);
  stroke: var(--color-main);
}

.disabled svg {
  fill: var(--color-main-light);
  stroke: var(--color-main-light);
}

.color-blue.text svg,
.color-blue.border svg {
  fill: var(--color-blue);
  stroke: var(--color-blue);
}

.color-blue.disabled svg {
  fill: var(--color-blue-light);
  stroke: var(--color-blue-light);
}

.color-red.text svg,
.color-red.border svg {
  fill: var(--color-red);
  stroke: var(--color-red);
}

.color-red.disabled svg {
  fill: var(--color-red-light);
  stroke: var(--color-red-light);
}

.fill:not(.disabled):hover svg {
  fill: var(--color-main);
  stroke: var(--color-main);
}

.border:not(.disabled):hover svg {
  fill: var(--color-white);
  stroke: var(--color-white);
}

.color-blue {
  color: var(--color-blue);

  &.fill {
    background-color: var(--color-blue);
    color: var(--color-white);
  }

  &.fill:not(.disabled):hover {
    background-color: transparent;
    color: var(--color-blue);
    border: 1px solid var(--color-blue);
  }

  &.fill:not(.disabled):hover svg {
    fill: var(--color-blue);
    stroke: var(--color-blue);
  }

  &.border {
    border: 1px solid var(--color-blue);
    color: var(--color-blue);
  }

  &.border:not(.disabled):hover {
    background-color: var(--color-blue);
    color: var(--color-white);
  }

  &.dashed {
    border: 1px dashed var(--color-blue);
    color: var(--color-blue);
  }

  &.dashed:not(.disabled):hover {
    background-color: var(--color-blue);
    color: var(--color-white);
  }

  &.border:not(.disabled):hover svg {
    fill: var(--color-white);
    stroke: var(--color-white);
  }

  &.disabled {
    color: var(--color-blue-light);
    background-color: var(--color-grey);
    border-color: var(--color-blue-light);
  }
}

.color-red {
  color: var(--color-red);

  &.fill {
    background-color: var(--color-red);
    color: var(--color-white);
  }

  &.fill:not(.disabled):hover {
    background-color: transparent;
    color: var(--color-red);
    border: 1px solid var(--color-red);
  }

  &.fill:not(.disabled):hover svg {
    fill: var(--color-red);
    stroke: var(--color-red);
  }

  &.border {
    border: 1px solid var(--color-red);
    color: var(--color-red);
  }

  &.border:not(.disabled):hover {
    background-color: var(--color-red);
    color: var(--color-white);
  }

  &.dashed {
    border: 1px dashed var(--color-red);
    color: var(--color-red);
  }

  &.dashed:not(.disabled):hover {
    background-color: var(--color-red);
    color: var(--color-white);
  }

  &.border:not(.disabled):hover svg {
    fill: var(--color-white);
    stroke: var(--color-white);
  }

  &.disabled {
    color: var(--color-red-light);
    border-color: var(--color-red-light);
  }
}
