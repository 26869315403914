.root {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.logo {
  font-size: 32px;
  font-weight: 700;
}

.menu {
  display: flex;
  flex-flow: row nowrap;
  gap: 40px;
}

.link {
  font-size: 16px;
  font-weight: 400;
  color: var(--color-main-extra-dark);
  transition: all .2s linear;
  padding-bottom: 3px;
  border-bottom: 2px solid transparent;

  &:hover {
    border-bottom-color: var(--color-blue);
  }
}

.active {
  color: var(--color-main);
  border-bottom-color: var(--color-blue);
}
