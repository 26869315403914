.rootWrapper {
  display: flex;
  flex: 1 1;
  align-items: center;
  justify-content: center;
}

.root {
  display: flex;
  flex: 0 1 460px;
  flex-flow: column nowrap;
  align-items: center;
  margin-top: calc(-32px - 24px);
}

.formWrapper {
  display: flex;
  flex-flow: column nowrap;
  gap: 32px;
  padding: 40px;
  background-color: var(--color-white);
  border-radius: 10px;
  max-width: 460px;
  width: 100%;
}

.title {
  font-size: 32px;
  line-height: 1;
  color: var(--color-white);
  margin-bottom: 24px;
}

.buttons {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-top: 26px;
}

.error {
  color: var(--color-red);
}

.alertText a {
  color: var(--color-main);
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

