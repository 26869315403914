.root {
  width: 100%;
}

.select {
  margin-bottom: 30px;
}

.chart {
  height: 340px;
}
