.root {
  position: relative;
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
}

.root-column {
  position: relative;
  display: flex;
  align-items: center;
  flex-flow: column nowrap;
}

.svgWrapper {
  order: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  flex-shrink: 0;
}

.svgWrapper > svg {
  color: inherit;
  fill: currentcolor;
  stroke: currentcolor;
}

.left {
  display: inline-block;
  order: 1;
  margin-right: 10px;
}

.right {
  display: inline-block;
  order: 3;
  margin-left: 10px;
}

.up {
  display: inline-block;
  order: 1;
  margin-bottom: 10px;
}

.down {
  display: inline-block;
  order: 3;
  margin-top: 10px;
}

.tooltip {
  position: absolute;
  z-index: 9999;
  display: flex;
  visibility: hidden;
  opacity: 0;
  top: 50%;
  left: 50%;
  padding: 2px 8px;
  background-color: var(--color-white);
  border: 1px solid var(--color-grey);
  border-radius: 4px;
  transition: opacity 300ms cubic-bezier(0.23, 1, 0.32, 1) 300ms;
}

.svgWrapper:hover + .tooltip {
  visibility: visible;
  opacity: 1;
  transition: opacity 500ms cubic-bezier(0.23, 1, 0.32, 1) 500ms;
}
