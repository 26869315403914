.root {
  display: flex;
  color: var(--color-main);
  font-size: 14px;
  line-height: 17.75px;
  font-weight: 400;
}

.clicked {
  cursor: pointer;
}

.disabled {
  cursor: default;
  color: var(--color-main-light);
}
