.overlay {
  position: fixed;
  inset: 0;
  width: 100vw;
  height: 100vh;
  background: var(--color-main-extra-dark);
  z-index: 1000000;
  overflow: auto;
}

.overlay.center {
  display: flex;
  padding: 32px 0;
}

.modal {
  display: flex;
  flex-flow: column nowrap;
  min-height: 100px;
  background-color: var(--color-white);
  padding: 40px;
  border-radius: 10px;
}

.modal h2 {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.33;
  color: var(--color-main);
}

.modal:focus {
  outline: none;
}

.modal.center {
  margin: auto;
}

.modal.left {
  margin: 24px auto 24px 24px;
  height: calc(100% - 48px);
}

.modal.right {
  margin: 24px 24px 24px auto;
  height: calc(100% - 48px);
}

.modal.full {
  margin: 0;
  min-width: 100vw;
  border-radius: 0;
  min-height: 100vh;
}

.buttons {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  gap: 16px;
  margin-top: 16px;
}

@media (width < 1280px) and (width >= 800px) {
  .overlay.center {
    padding: 16px 0;
  }

  .modal {
    padding: 16px;
  }
}

@media (width < 800px) {
  .overlay.center {
    padding: 16px 0;
  }

  .modal {
    padding: 16px;
  }
}
