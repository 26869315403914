.table {
  th.small {
    width: 40px;
  }

  th.big {
    width: 46%;
  }

  th.dotMenu {
    width: 22px;
  }

  td.dotMenu {
    padding-left: 0;
    padding-right: 0;
    border: none;
    color: var(--color-blue-light);
  }

  .dotMenuActive {
    color: var(--color-blue);
  }

  .blocked {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;

    svg {
      color: var(--color-red);
    }
  }
}
