.root {
  background-color: var(--color-white)
}

.dragDots {
  svg {
  max-width: 10px;
  height: auto;
  }
}
