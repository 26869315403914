.header {
  position: relative;
}

.helper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 100;
  top: -24px;
  left: -24px;
  background: var(--color-blue);
  color: var(--color-white);
  width: 24px;
  height: 24px;
  border-radius: 14px 14px 3px 14px;
  font-weight: 500;
  transition: width .2s, height .2s, border-radius .2s;
  cursor: pointer;

  & > :last-child {
    display: none;
  }

  &:hover {
    width: 240px;
    height: 48px;
    border-radius: 8px;

    & > :first-child {
      display: none;
    }

    & > :last-child {
      display: flex;
      text-align: center;
      color: inherit;
    }
  }
}

.standardSelect {
  width: 480px;
}

.table {
  th.small {
    width: 40px;
  }

  th.dotMenu {
    width: 22px;
  }

  td.dotMenu {
    padding-left: 0;
    padding-right: 0;
    border: none;
    color: var(--color-blue-light);
  }

  .dotMenuActive {
    color: var(--color-blue);
  }

  .blocked {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;

    svg {
      color: var(--color-red);
    }
  }
}
