.list {
  position: relative;
  border: none;
  user-select: none;
  font-size: 16px;
}

.list.background {
  padding: 15px 20px;
}

.head {
  cursor: pointer;
}

.list:hover.background.hover,
.list.background.active {
  background-color: var(--color-white);
  border-radius: 10px;
}

.children {
  padding: 15px 20px;
  display: none;
  position: absolute;
  min-width: 100%;
  border-radius: 10px;
  row-gap: 6px;
}

.children.down {
  top: 100%;
  transform: translateY(10px);
  left: 0;
}

.background .children.down {
  transform: translateY(-15px);
}

.children.left {
  top: 0;
  right: 100%;
}

.children.right {
  top: 100%;
  left: 100%;
}

.children.border {
  padding: 30px;
  border: 1px solid var(--color-blue)
}

.list.active .children,
.list:hover.hover .children {
  display: flex;
  flex-direction: column;
  row-gap: 6px;
  background-color: var(--color-white);
}
